import React, { useState, useContext } from "react";
import Stars from "../block/Stars";
import { GlobalDataContext } from "../../context/context";



function Faq() {
    const { rpdata } = useContext(GlobalDataContext);

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
          UserName: "Ken W.",
          Date: "jul 07, 2022",
          Rate: 5,
          Description: "Great attention to detail. Omar was great!",
          red: "HomeAdvisor",
          enlace: "https://www.homeadvisor.com/rated.finisherspainting.82424491.html",
          ima: "https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fhomeadvisor.png?alt=media&token=5f7bbdeb-e5a8-470a-8a63-ee34d6e60661"
        },
        {
          UserName: "Mary D",
          Date: "Jul 02, 2022",
          Rate: 5,
          Description: "I spoke to Omar and got an estimate, and next day he came and did the job. It was exterior painting after rewiring an old house, priming and painting metal, priming and painting stucco. I am now speaking to him about interior painting. I found him easy to work with and certainly efficient, quite knowledgeable about stucco and metal priming and painting.",
          red: "HomeAdvisor",
          enlace: "https://www.homeadvisor.com/rated.finisherspainting.82424491.html",
          ima: "https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fhomeadvisor.png?alt=media&token=5f7bbdeb-e5a8-470a-8a63-ee34d6e60661",
          
        },
      
      ]
      
     


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src={rpdata?.stock?.[17]}
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item?.UserName} </span>
                                        <Stars score={item?.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item?.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item?.Description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[95px] h-[25px] flex items-center">
                                    <img src={item?.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item?.enlace}>{item?.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;